@import "../../variables.scss";

.ui-inner-card {
  border-radius: 16px;
  background-color: $color-primary;
  margin-bottom: 12px;
  padding: 12px;

  > h3 {
    margin-bottom: 12px;
  }
}
