@use "sass:color";
@import "../../variables.scss";

.cta {
  border: $color-cta 4px solid;
  padding: 12px 24px;
  font-weight: bold;
  color: $color-cta;
  background-color: color.adjust($color-cta, $lightness: 40%);
  border-radius: 4px;
  display: inline-block;

  &:visited {
    color: $color-cta;
  }

  &:hover {
    background-color: color.adjust($color-cta, $lightness: 30%);
  }
}
