@import "../../variables.scss";

.page-home {
  .hero {
    background-color: $color-secondary;
    padding: 24px 24px 60px 24px;
    display: flex;
    justify-content: center;
    color: $color-primary;

    .hero-content-wrapper {
      width: 100%;
      max-width: 1200px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      @media only screen and (max-width: 800px) {
        flex-wrap: wrap;

        > .hero-content:first-child {
          margin-bottom: 24px;
        }
      }
    }

    .hero-content {
      width: 100%;
      margin: 0 24px;
    }
  }

  .office-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div:first-child > div {
      margin-right: 24px;
    }
  }

  .card-list {
    max-width: 1000px;
    width: 100%;
    margin: -30px auto 0 auto;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ui-card > h2,
  .ui-inner-card > h3 {
    margin: 0;
  }
}
