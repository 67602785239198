.labeled-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  .label {
    font-size: 0.75rem;
    font-weight: bold;
  }
}
