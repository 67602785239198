@import "../../variables.scss";

.ui-card {
  border-radius: 16px;
  background-color: $color-primary;
  margin-bottom: 24px;
  padding: 24px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  color: $color-secondary;
  width: 100%;

  > h1,
  > h2,
  > h3,
  > h5 {
    margin-top: 0;
  }
}
